// Импортируйте axiosInstance, если он вам нужен
import axiosInstance from '../../helpers/instance';

const state = {
  countries: null,
  warehouses: null,
  warehouse: null,
  orders: null,
};

const getters = {};

const actions = {
  async GetCountriesList({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('get_countries_list');

      if (response.data && response.data.data) {
        commit('setCountries', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getWarehouses({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';
  
      const response = await axiosInstance.get('admin/warehouse/index');
  
      if (response.data && response.data.data) {
        commit('setWarehouses', response.data.data);
      }
  
      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getWarehouseId({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';
  
      const response = await axiosInstance.get('admin/warehouse/find?id=' + id);
  
      if (response.data && response.data.data) {
        commit('setWarehouse', response.data.data);
      }
  
      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async updateWarehouse({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      let payload = body;
  
      const response = await axiosInstance.post('admin/warehouse/update', payload);
  
      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async registerUser({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('admin/warehouse/register_user', body);
  
      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async deleteUser({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('admin/warehouse/unlink_user?id=' + body.id);
  
      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getWarehousesOrder({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';
  
      const response = await axiosInstance.get('admin/warehouse/orders');
  
      if (response.data && response.data.data) {
        commit('setWarehousesOrders', response.data.data);
      }
  
      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
};

const mutations = {
  setCountries(state, data) {
    state.countries = data;
  },
  setWarehouses(state, data) {
    state.warehouses = data;
  },
  setWarehouse(state, data) {
    state.warehouse = data;
  },
  setWarehousesOrders(state, data) {
    state.orders = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
