// Импортируйте axiosInstance, если он вам нужен
import axiosInstance from '../../helpers/instance';

const state = {
  banner: null,
  banners: null,
  orders: null,
  order: null,
  status: null,
  binar: null
};

const getters = {};

const actions = {
  async getBanners({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('admin/banners/index');

      if (response.data && response.data.data) {
        commit('setBanners', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getBanner({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('admin/banners/edit?id=' + id);

      if (response.data && response.data.data) {
        commit('setBanner', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getOrders({ commit }, filter = {}) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';
  
      // Добавляем параметры фильтрации в запрос
      const response = await axiosInstance.get('admin/orders/index', {
        params: filter
      });
  
      if (response.data && response.data.data) {
        commit('setOrders', response.data.data);
      }
  
      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getOrderId({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';
  
      // Добавляем параметры фильтрации в запрос
      const response = await axiosInstance.get('admin/orders/find?order_id=' + id);
  
      if (response.data && response.data.data) {
        commit('setOrder', response.data.data);
      }
  
      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getStatus({ commit }, filter = {}) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';
  
      const response = await axiosInstance.get('admin/orders/statuses');
  
      if (response.data && response.data.data) {
        commit('setStatus', response.data.data);
      }
  
      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async createBanner({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('admin/banners/store', body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async updateBanner({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('admin/banners/update', body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async createAbout({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('admin/pages/about_company/store_or_update_page_block', body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async createPartners({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('admin/pages/partners/store_or_update_page_block', body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async createCustomers({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('admin/pages/for_customers/store_or_update_page_block', body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async createSchools({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('admin/pages/business_school/store_or_update_page_block', body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getBinary({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';
      console.log(id)
      const response = await axiosInstance.get('admin/users/get_binar?user_id=' + id,);

      if (response.data && response.data.data) {
        commit('setBinar', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getBinarOther({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('/cabinet/profile/marketing/get_binar?user_id=' + id);
  
      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      if(error && error.response && error.response){
        if(error.response.status == 403 || error.response.status == 401){
          localStorage.removeItem('authToken');
          router.push('/')
        }
      }
      throw error;
    }
  },
  async getChangeStatus({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('admin/warehouse/orders/change_status', body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
};

const mutations = {
  setBanner(state, data) {
    state.banner = data;
  },
  setBanners(state, data) {
    state.banners = data;
  },
  setOrders(state, data) {
    state.orders = data;
  },
  setOrder(state, data) {
    state.order = data;
  },
  setStatus(state, data) {
    state.status = data;
  },
  setBinar(state, data) {
    state.binar = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
